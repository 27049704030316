import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import logo from '@/public/images/logo.svg';
import dark_logo from '@/public/images/logo_dark.svg';

import NextImage from '../../UI/nextImage/NextImage';

import styles from './Logo.module.scss';

type TLogo = {
    isFooter?: boolean;
    isHeaderDark?: boolean;
};

const Logo: FC<TLogo> = ({ isFooter, isHeaderDark }) => {
    return (
        <Link href="/" className={`${styles.logo}`}>
            <NextImage
                alt="High Five Themes"
                src={isHeaderDark && !isFooter ? dark_logo : logo}
                fill
                sizes="(max-width: 820px) 176px, (max-width: 1024px) 159px, 176px"
                priority
            />
        </Link>
    );
};

export default Logo;
