import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import navArr from '@/src/assets/objects/navArr';

import Wrapper from '../../UI/wrapper/Wrapper';
import Logo from '../Logo/Logo';

import styles from './Footer.module.scss';

const Footer = () => {
    const router = useRouter();

    return (
        <footer className={styles.footer}>
            <Wrapper className={styles.wrapper}>
                <Logo isFooter={true} />
                <nav className={styles.nav}>
                    <ul className={styles.nav__list}>
                        {navArr.map((item, i) => {
                            const pageClass = classNames(styles.nav__item, {
                                [styles.currPage]: router.pathname === item.navHref,
                            });

                            return (
                                <li
                                    className={`${styles.nav__item} ${pageClass}`}
                                    key={`${item.navName}-${i}`}>
                                    <Link href={item.navHref}>{item.navName}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                <p className={styles.copyright}>Copyright © All Rights Reserved</p>
            </Wrapper>
        </footer>
    );
};

export default Footer;
