import Image from 'next/image';
import { FC } from 'react';

interface MyImageProps {
    src: string;
    alt: string;
    width?: number;
    height?: number;
    fill?: boolean;
    sizes?: string;
    quality?: number;
    priority?: boolean;
    className?: string;
    style?: any;
    loading?: 'lazy' | 'eager';
}

const NextImage: FC<MyImageProps> = ({
    src,
    alt,
    width,
    height,
    fill = false,
    quality = 80,
    priority = false,
    className,
    style,
    loading = 'eager',
    sizes,
}: MyImageProps) => {
    return (
        <Image
            src={src}
            alt={alt}
            width={width}
            height={height}
            fill={fill}
            quality={quality}
            priority={priority}
            className={className}
            loading={loading}
            sizes={sizes}
            style={style}
        />
    );
};
export default NextImage;
