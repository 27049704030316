import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';

import navArr from '@/src/assets/objects/navArr';

import Button from '../UI/button/Button';

import styles from './Nav.module.scss';

type TNav = {
    isNavActive: boolean;
    setIsNavActive: (isActive: boolean) => void;
    isHeaderDark: boolean;
};

const Nav: FC<TNav> = ({ isNavActive, setIsNavActive, isHeaderDark }) => {
    const router = useRouter();
    const navStyle = classNames(styles.nav, {
        [styles.active]: isNavActive,
        [styles.dark__mode]: isHeaderDark,
    });

    useEffect(() => {
        if (isNavActive) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isNavActive]);

    return (
        <nav className={navStyle}>
            <ul className={styles.nav__list}>
                {navArr.map((item, i) => {
                    const pageClass = classNames(styles.nav__item, {
                        [styles.currPage]: router.pathname === item.navHref,
                    });
                    return (
                        <li
                            className={pageClass}
                            onClick={() => setIsNavActive(false)}
                            key={`${item.navName}-${i}`}>
                            <Link href={item.navHref}>{item.navName}</Link>
                        </li>
                    );
                })}
            </ul>
            <div className={styles.btnWrapper}>
                <Button
                    className={styles.nav__btn}
                    buttonUrl="/themes"
                    ariaLabel="Discover Themes"
                    color="blue">
                    Discover Themes
                </Button>
            </div>
        </nav>
    );
};

export default Nav;
