const url = process.env.NEXT_PUBLIC_BASE_URL
    ? process.env.NEXT_PUBLIC_BASE_URL.trim()
    : 'https://highfivethemes.com';

const baseUrl = (path?: string | undefined) => {
    if (path) {
        const param = path.trim()[0] === '/' ? path.trim().slice(1) : path.trim();
        return url.slice(-1) === '/' ? url + param + '/' : `${url}/${param}/`;
    } else {
        return url.slice(-1) === '/' ? url : url + '/';
    }
};

export { baseUrl };
