import classNames from 'classnames';
import Link from 'next/link';
import { FC, ReactNode } from 'react';

import styles from './Button.module.scss';

type TButton = {
    children: ReactNode;
    color?: 'blue' | 'standard' | 'black' | 'blue__divided';
    buttonUrl?: string;
    onClick?: () => void;
    ariaLabel?: string;
    ariaLabelledby?: string;
    target?: boolean;
    className?: string;
    type?: string;
    formBtn?: boolean;
    isLoading?: boolean;
};

const Button: FC<TButton> = ({
    color,
    onClick,
    buttonUrl,
    children,
    ariaLabel,
    target,
    className,
    formBtn,
    isLoading,
}) => {
    const btnColor = styles[`${color}`];

    return (
        <>
            {buttonUrl ? (
                <Link
                    href={buttonUrl}
                    className={classNames(styles.btn, btnColor, className, {
                        [styles.formBtn]: formBtn,
                    })}
                    aria-label={ariaLabel}
                    target={target ? `_blank` : undefined}
                    rel={target ? 'noopener noreferrer' : undefined}>
                    {children}
                    {btnColor === 'standard' && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none">
                            <path
                                d="M6.43674 5.19924L12.7123 5.28763M12.7123 5.28763L12.8007 11.5632M12.7123 5.28763L5.28769 12.7123"
                                stroke="#0A163C"
                                strokeWidth="1.7"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}
                </Link>
            ) : (
                <button
                    type="submit"
                    className={classNames(styles.btn, btnColor, className, {
                        [styles.formBtn]: formBtn,
                        [styles[`formBtn--isLoading`]]: isLoading,
                    })}
                    onClick={onClick}
                    aria-label={ariaLabel}>
                    {children}
                </button>
            )}
        </>
    );
};

export default Button;
