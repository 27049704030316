import Footer from './Footer/Footer';
import Header from './Header/Header';
import StickyHeader from './stickyHeader/StickyHeader';
import React, { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react';

import { JSONLDInterface } from '@/src/assets/interfaces/jsonLDInterface';
import { IMeta } from '@/src/assets/interfaces/metaInterface';

import Meta from '../meta/Meta';

import styles from './Layout.module.scss';

type Layout = {
    children: ReactNode;
    meta: IMeta | null;
    jsonLD?: JSONLDInterface;
    className?: string;
};

const Layout: FC<Layout> = ({ children, className, meta, jsonLD }) => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const ref: RefObject<HTMLDivElement> = useRef(null);
    const [scrollLength, setScrollLength] = useState(0);
    const [showUpBtn, setShowUpBtn] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScroll: number = window.scrollY;

            if (currentScroll <= 50) {
                setShowUpBtn(false);
            } else {
                setShowUpBtn(true);
            }

            setScrollLength(currentScroll);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showUpBtn, scrollLength]);

    return (
        <Meta meta={meta} jsonLD={jsonLD}>
            <div className="scroll-fix"></div>
            <div className={`${styles.layout}`} ref={ref}>
                <StickyHeader>
                    <Header />
                </StickyHeader>
                <main className={`main ${className}`}>{children}</main>
                {showUpBtn && (
                    <div onClick={scrollToTop} className={styles.scroll__button}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none">
                            <path
                                d="M6 12L10 8L14 12"
                                stroke="#0A163C"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                )}
                <Footer />
            </div>
        </Meta>
    );
};

export default Layout;
