const navArr = [
    {
        navName: 'Themes',
        navHref: '/themes',
    },
    {
        navName: 'Bundle ⚡',
        navHref: '/bundle',
    },
    {
        navName: 'Blog',
        navHref: '/blog',
    },
    {
        navName: 'Contacts',
        navHref: '/contacts',
    },
    {
        navName: 'Support',
        navHref: '/support',
    },
];

export default navArr;
