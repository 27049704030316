import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { FC, ReactNode, useEffect, useState } from 'react';

import { JSONLDInterface } from '@/src/assets/interfaces/jsonLDInterface';
import { baseUrl } from '@/src/assets/utils/baseUrl';

import { IMeta } from '../../assets/interfaces/metaInterface';

type Meta = {
    children: ReactNode;
    meta?: IMeta | null;
    jsonLD?: JSONLDInterface;
};

const Meta: FC<Meta> = ({ children, meta, jsonLD }) => {
    const [href, setHref] = useState('');

    useEffect(() => {
        setHref(window.location.href);
    }, []);

    const path = usePathname();

    return (
        <>
            <Head>
                <title>{meta?.metaTitle || 'High Five Themes'}</title>
                <meta
                    name="description"
                    content={
                        meta?.metaDescription ??
                        'Harness the ethereal power of this platform to captivate your audience and unleash the full potential of your content.'
                    }
                />
                <meta property="og:title" content={meta?.metaTitle ?? 'High Five Themes'} />
                <meta
                    property="og:description"
                    content={
                        meta?.metaDescription ??
                        'Harness the ethereal power of this platform to captivate your audience and unleash the full potential of your content.'
                    }
                />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={href} />
                <meta
                    property="og:image"
                    content={
                        meta?.metaImageUrl
                            ? meta.metaImageUrl
                            : baseUrl('/images/deafultMetaImage.jpg')
                    }
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@HighFiveThemes" />
                <meta name="twitter:creator" content="@Ashstones" />
                <meta
                    name="twitter:image"
                    content={
                        meta?.metaImageUrl
                            ? meta.metaImageUrl
                            : baseUrl('/images/deafultMetaImage.jpg')
                    }
                />
                <meta name="twitter:title" content={meta?.metaTitle ?? 'High Five Themes'} />
                <meta name="twitter:url" content={href} />
                <meta
                    name="twitter:description"
                    content={
                        meta?.metaDescription ??
                        'Harness the ethereal power of this platform to captivate your audience and unleash the full potential of your content.'
                    }
                />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" href="/favicon.svg" />
                <link rel="canonical" href={baseUrl(path.slice(1, -1))} />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD) }}
                />
            </Head>
            {children}
        </>
    );
};

export default Meta;
